import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/dc-logo.png';

const Header: React.FC = () => {
    return (
        <header className="bg-blue-600 text-white p-4">
            <div className="container mx-auto">
                <nav className="flex items-center justify-between">
                    <Link to="/" className="text-3xl font-bold">
                        <img src={logo} alt="The Dream Centre Logo" style={{ height: "35px" }} className="mr-4 float-left" />
                        DC Transcriber
                    </Link>
                    <div>
                        <Link to="/" className="mr-4 hover:text-blue-300">
                            Upload Audio
                        </Link>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
